export const sliderImages = [
    {
      key: "1",
      image: `${process.env.REACT_APP_IMG_PATH}/slide-1.webp`,
    },
    {
      key: "2",
      image: `${process.env.REACT_APP_IMG_PATH}/slide-2.webp`,
    },
    {
      key: "3",
      image: `${process.env.REACT_APP_IMG_PATH}/slide-3.webp`,
    },
  ]

export const defaultValidators = {
    password: {
      message:
        "Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number",
      rule: (val, params, validator) => {
        var patt = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return patt.test(val);
      },
    }
  }
import React, { useState } from 'react'
import Login from '../authentication/login/login'
import Signup from '../authentication/signup/signup'
import Testimonials from '../homepage/testimonials'
import Footer from '../shared/footer/footer'
import Header from '../shared/header/header'

const AboutUs = () => {
    const [state, setState] = useState({
        popupVisible: false,
        isLoginModalVisible:false,
        isSignupModalVisible:false,
        text: null,
      })
      const handleLoginModal = (bool) => { setState({...state, isLoginModalVisible : bool }) }

      const handleSignupModal = (bool) => { setState({...state, isSignupModalVisible: bool }) }
    
  return (
    <>
    <Header handleLoginModal={handleLoginModal} handleSignupModal={handleSignupModal} />
    <Login isModalVisible={state.isLoginModalVisible} handleModal={handleLoginModal} />
    <Signup isModalVisible={state.isSignupModalVisible} handleModal={handleSignupModal} />


    <section className="home-banner">

<img src="./images/aboutus-banner.jpg" alt=""/>


<div className="tag">
  <h1>We want your passion for music</h1>
  <h2>to be heard everywhere on Earth.</h2>

</div>


</section>




<section className="aboutus background1">

<div className="container">



  <div className="feature_boxes row_parent">

    <div className="row flex1 mb-10">

      <div className="col_left">
        <div className="heads">

          <h3>Your</h3>
          <h4>talent</h4>
          <span className="line"></span>
        </div>

        <p>We created Fanify because we believe every artist deserves to have their music heard by an audience who convert into a fanbase.</p>

        <p>While experiencing how the music industry is changing and the struggles that artists go through, we set out to create something that takes the pain out of music promotion and does all the work for you.</p>

      </div>

      <div className="col_right">
        <div className="img">
          <img src="../images/talent.jpg" alt=""/>
        </div>


      </div>

    </div>


    <div className="row flex1 mb-10">

      <div className="col_left">
        <div className="heads">

          <h3>Our</h3>
          <h4>knowledge</h4>
          <span className="line"></span>
        </div>

        <p>Fanify was created in 2021 by an award winning
          team of Irish music and technology professionals.</p>

        <p>The core founders have over 5 decades of
          combined music and technology experience -
          including building multiple successful global
          technology startups and a portfolio of extensive
          music industry enterprises (e.g. music production, artist promotion and record label management).</p>
      </div>

      <div className="col_right">

        <div className="img">
          <img src="../images/knowledge.jpg" alt=""/>
        </div>




      </div>

    </div>

    <div className="row flex1">

      <div className="col_left">

        <div className="heads">

          <h3>Your</h3>
          <h4>Success</h4>
          <span className="line"></span>
        </div>
        <p>You are an artist and not a marketer. Spend your time doing what you love and we'll help you achieve your music career goals.</p>

        <p>Go back to playing music and let Fanify build your fanbase. </p>

      </div>

      <div className="col_right ">

        <div className="img">
          <img src="../images/success.jpg" alt=""/>
        </div>


      </div>

    </div>


  </div>


</div>
</section>

<section className="artist-label background2">

<div className="container">

  <div className="heads">

    <h3>+10K Artists + Lables</h3>
    <h4>on Fanify </h4>
    <span className="line"></span>
  </div>

<Testimonials/>

 </div>


</section>
  <Footer />
    </>
  )
}

export default AboutUs
import React from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { GetToken } from "../../../firebaseinit";
import { openNotificationWithIcon } from "../../openNotification/openNotification";
import authAction from "../../../redux/auth/actions";

const FacebookLoginButton = ({
  submit,
  state,
  props,
  openNotificationWithIcon,
  setState,
  api,
  apiConst,
  validator,
  responseHelper,
  makePayment,
  buttonText,
}) => {
  console.log('Facebook login.......');
  const responseFacebook = async (response) => {
    console.log('resppnse fackbeooooook' + response);
    if (response.status === "unknown") {
      window.FB.logout();
    } else {
      const values = {
        name: response.name,
        fbUserId: response.userID,
      };
      console.log("VAL",values)
      submit(
        null,
        values,
        true,
        api,
        apiConst,
        responseHelper,
        makePayment,
        validator,
        state,
        props,
        openNotificationWithIcon,
        setState
      );
    }
  };

  return (



<FacebookLogin
  appId="1963464637163555"  
  onSuccess={(response) => {
    console.log('Login Success!', response);
  }}
  onFail={(error) => {
    console.log('Login Failed!', error);
  }}
  onProfileSuccess={(response) => {
    console.log('Get Profile Success!', response);
  }}
  />


  );
};

export const connectWithFB = async (
  res,
  userData,
  api,
  apiConst,
  responseHelper,
) => {
  try {

    let data = { userId: userData._id, fbData: res}
    const response = await api.post(apiConst.connectWithFb, { data });
    
    const responseData = responseHelper(response, true);
    if (responseData && responseData.success) {
      return true
    }
  } catch (e) {
      console.log(e);
      openNotificationWithIcon("error", "Error", "Oops Something Went Wrong");
      return false
    }
  }

  export const checkPremiumUser = async (
    api,
    apiConst,
    responseHelper,
    setIsPremiumUser
  ) => {
    try {
      
        api.get(apiConst.checkPremiumUser).then((response) => {
          const data = responseHelper(response);
          if (data?.logout) {
            return;
          }
          if (data && data.success) {
            setIsPremiumUser(data.data.isPremiumUser)
          } else {
            console.log("Error while checking for User Premium")
          }
        });
    } catch (e) {
      console.log("error", "Error", "Oops Something Went Wrong");
    }
  };

export const submit = async (
  e,
  values,
  isFacebook,
  api = null,
  apiConst = null,
  responseHelper = null,
  validator = null,
  state = null,
  history,
  dispatch,
  setState = null,
  stateError = null
) => {
  !isFacebook && e.preventDefault();
  const { login, storeToken } = authAction;
  const validation = !isFacebook ? validator.current.allValid() : true;
  
  if (validation) {
    setState("isSubmitLoading", true);
    try {
      const fcmToken = await GetToken();
      const facebookData = {
        ...values,
        notificationToken: fcmToken || null,
        type: "facebook",
      };
      let data = !isFacebook
        ? {
            email: state.fields.email ? state.fields.email : null,
            password: state.fields.password ? state.fields.password : null,
            notificationToken: fcmToken || null,
          }
        : facebookData;
      const response = await api.post(apiConst.login, { data });

      const responseData = responseHelper(response, true);
      if (responseData && responseData.success) {
        localStorage.setItem("token", responseData.token);
        dispatch(login({ user: responseData.data }))
        dispatch(storeToken({ token: responseData.token }))
        openNotificationWithIcon(responseData?.type, responseData?.title, responseData?.message );
        history.push("/dashboard");
      } else {
        if(!responseData.data){
          stateError('isBothTrue', true)
        }else{
          stateError('isBothTrue', false)
        }
      }
      setState("isSubmitLoading", false);
      if (state.checked) {
        localStorage.setItem("isChecked", true);
        localStorage.setItem("email", state.fields.email);
        localStorage.setItem("password", state.fields.password);
      } else {
        localStorage.setItem("isChecked", false);
        localStorage.setItem("email", "");
        localStorage.setItem("password", "");
      }
    } catch (e) {
      console.log(e);
      openNotificationWithIcon("error", "Error", "Oops Something Went Wrong");
      setState("isSubmitLoading", false);
    }
  } else {
    setState("errors", validator.current.getErrorMessages());
    validator.current.showMessages();
  }
};

export default FacebookLoginButton;

import React, { useRef, useState } from 'react'
import Api from '../../../utility/apiHandler';
import SimpleReactValidator from 'simple-react-validator';
import { apiConst } from '../../../constants/api';
import { responseHelper } from '../../../utility/response';
import { openNotificationWithIcon } from '../../openNotification/openNotification';
import { FormMain } from '../authentication-style';
import { Link, useHistory } from 'react-router-dom';
import { Checkbox, Input } from 'antd';
import { defaultValidators } from '../authHelper'
import Modal from 'antd/lib/modal/Modal';

const api = new Api()

const Signup = ({isModalVisible, handleModal, handleLoginModal}) => {
    const [state, setState] = useState({
        tab1: true,
        tab2: false,
        fields: {},
        errors: {
          "isExists": null
        },
        isSubmitLoading: false,
        showPassword: false,
        showConfirmPassword: false,
        checked: false,
      })
      const history = useHistory()
      const [isAfterSignupModal, setIsAfterSignupModal] = useState(null)
      const validator = useRef(new SimpleReactValidator({
        validators: defaultValidators
      }))
      const handleChange = (field, e) => {
        let fields = state.fields;
    
        if (e.target.value.trim() == "") {
          fields[field] = "";
        } else {
          fields[field] = e.target.value;
        }
        setState({...state,  fields });
      };
      
      const tab1 = () => {
        setState({...state, tab1: true, tab2: false });
      };
    
      const tab2 = () => {
        setState({...state, tab1: false, tab2: true });
      };

      const handleAfterSignup = (value) => {
        setIsAfterSignupModal(value)
      };

      const stateError = (field, value) => {
        let errors = state.errors
        errors[field] = value
        setState({...state, errors})
      }

      const submit = (e, values, isFacebook) => {
        !isFacebook && e.preventDefault();
        const validation = !isFacebook ? validator.current.allValid() : true;
        if (validation) {
          setState({...state, isSubmitLoading: true, });
          const facebookData = {
            ...values,
            type: "facebook",
          };
          let data = !isFacebook ?
             {
                email: state.fields.email,
                password: state.fields.password,
                firstName: state.fields.first_name,
                lastName: state.fields.last_name,
                bandName: state.fields.artist_band,
                youtube: state.fields.youtube,
                facebook: state.fields.facebook,
                spotify: state.fields.spotify,
              }
            : facebookData;
          api
            .post(apiConst.signup, { data })
            .then((response) => {
              const data = responseHelper(response, true);
              if (data && data.success) {
                handleModal(false)
                handleAfterSignup(true)
                !isFacebook
                  ? history.push("/")
                  : history.push("/dashboard");
                  if (state.checked) {
                    localStorage.setItem("isChecked", true);
                    localStorage.setItem("email", state.fields.email);
                    localStorage.setItem("password", state.fields.password);
                  } else {
                    localStorage.setItem("isChecked", false);
                    localStorage.setItem("email", "");
                    localStorage.setItem("password", "");
                  }
              } else {
                openNotificationWithIcon("error","error",data.message)
              }
              setState({...state, isSubmitLoading: false, });
            })
            .catch((e) => {
              console.log("E",e);
              openNotificationWithIcon(
                "error",
                "error",
                "Oops Something Went Wrong"
              );
              setState({...state, isSubmitLoading: false });
            });
        } else {
          setState({...state, errors: validator.current.getErrorMessages() });
          validator.current.showMessages();
        }
      };
      
    const redirectToSignin = () => {
      handleModal(false)
      handleLoginModal(true)
    }

    const handleShowPassword = (e, type) => {
      e.preventDefault()
      if(type === "showPassword"){
        setState({...state, showPassword: !state.showPassword})
      } else {
        setState({...state, showConfirmPassword: !state.showConfirmPassword})
      }
    }

  return (
    <>
    
  <Modal className='poup' centered visible={isAfterSignupModal} footer={null} onCancel={() => handleAfterSignup(false)}>
  <img src="../images/logo.png" className="logo1" alt=""/>
  <h4>Welcome to Fanify!</h4>
  <p>Please click on the link that has just been sent to your email account
  to verify your email and continue the registration process.
  </p>   
    </Modal>
    
    <Modal className='poup' centered visible={isModalVisible} footer={null} onCancel={() => handleModal(false)}>
  <img src="../images/logo.png" className="logo1" alt=""/>
    <h4>Sign up to build your fanbase</h4>
              
                  <div className="full-width signup-tab-title ">
                    <a
                      onClick={tab1}
                      className={`${state.tab1 ? "active" : ""}`}
                    >
                      Personal Information
                    </a>
                    /
                    {/* <a
                      onClick={tab2}
                      className={`${state.tab2 ? "active" : ""}`}
                    >
                      Business Information
                    </a> */}
                  </div>

                  <FormMain className='login_box'
                  >
                    {state.tab1 && (
                      <div>
                          <Input
                            placeholder="First Name"
                            value={state.fields["first_name"]}
                            onChange={(e) => handleChange("first_name",e)}
                            className={
                              state.errors.first_name ? "invalid" : ""
                            }
                          />
                          {validator.current.message(
                            "first_name",
                            state.fields["first_name"],
                            "required"
                          )}
                          <Input
                            placeholder="Last Name"
                            value={state.fields["last_name"]}
                            onChange={(e) => handleChange("last_name",e)}
                            className={
                              state.errors.last_name ? "invalid" : ""
                            }
                          />
                          {validator.current.message(
                            "last_name",
                            state.fields["last_name"],
                            "required"
                          )}
                          <Input
                            type="email"
                            value={state.fields["email"]}
                            onChange={(e) => handleChange("email",e)}
                            placeholder="Email ID"
                            pattern="/^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|AERO|ARPA|BIZ|COM|COOP|EDU|GOV|INFO|INT|MIL|MUSEUM|NAME|NET|ORG|PRO|TRAVEL|MOBI|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/"
                            className={state.errors.email ? "invalid" : ""}
                          />
                          {validator.current.message(
                            "email",
                            state.fields["email"],
                            "required|email"
                          )}
                          {state.errors.isExists === null ? null : <>
                          {state.errors.isExists === true ? <h6 style={{color: 'red', marginTop: '200px'}} >Email Already Exists</h6> : null}
                          </>}
                          <div className="password">
                          <Input
                            type={state.showPassword ? "text" : "password"}
                            placeholder="Create Password"
                            value={state.fields["password"]}
                            onChange={(e) => handleChange("password",e)}
                            className={
                              state.errors.password ? "invalid" : ""
                            }
                            autocomplete="new-password"
                          />
                          {validator.current.message(
                            "password",
                            state.fields["password"],
                            "required|password"
                          )}
                          <button onClick={(e) => handleShowPassword(e, "showPassword")}>
                          {state.showPassword ? 
                          <i class="fa fa-eye"></i> :
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        }
                        </button>
                        </div>
                        <div className="password">
                          <Input
                            type={state.showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            value={state.fields["confirm_password"]}
                            onChange={(e) => handleChange("confirm_password",e)}
                            className={state.errors.confirm_password ? "invalid" : ""
                            }
                          />
                          {validator.current.message(
                            "confirm password",
                            state.fields["confirm_password"],
                            `required|in:${state.fields.password}`,
                            { messages: { in: "Password needs to match!" } }
                          )}
                          <button onClick={(e) => handleShowPassword(e, "confirmPassword")}>
                          {state.showPassword ? 
                          <i class="fa fa-eye"></i> :
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        }
                        </button>
                        
                          </div>
                          <div className="full-width form-field text-checkbox top-margin">
                      <Checkbox
                        onChange={(e) => {setState({...state, checked: e.target.checked })}}
                        checked={state.checked}
                        style={{color:"whitesmoke"}}
                      >
                        Remember Me
                      </Checkbox>
                    </div>
                          {/* <button
                            type="primary"
                            className="btn"
                            loading={state.isSubmitLoading}
                            onClick={goNext}
                          >
                            <span>Next</span>
                          </button> */}
                          <button
                            type="primary"
                            className="btn"
                            loading={state.isSubmitLoading}
                            onClick={(e) => submit(e, null, false)}
                          >SIGN UP
                          </button>
                        </div>
                    )}
                    {state.tab2 && (
                      <div>
                          <Input
                            placeholder="Business Name"
                            value={state.fields["businessName"]}
                            onChange={(e) => handleChange("businessName",e)}
                            className={ state.errors.businessName ? "invalid" : "" }
                          />
                          <Input
                            placeholder="Contact Person"
                            value={state.fields["contactPerson"]}
                            onChange={(e) => handleChange("contactPerson",e)}
                            className={ state.errors.contactPerson ? "invalid" : "" }
                          />
                        <Input
                            type="email"
                            value={state.fields["businessEmail"]}
                            onChange={(e) => handleChange("businessEmail",e)}
                            placeholder="Email ID"
                            pattern="/^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|AERO|ARPA|BIZ|COM|COOP|EDU|GOV|INFO|INT|MIL|MUSEUM|NAME|NET|ORG|PRO|TRAVEL|MOBI|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/"
                            className={state.errors.businessEmail ? "invalid" : ""}
                          />
                          <Input
                            type="password"
                            placeholder="Create Password"
                            value={state.fields["password"]}
                            onChange={(e) => handleChange("password",e)}
                            className={
                              state.errors.password ? "invalid" : ""
                            }
                            autocomplete="new-password"
                          />
                          <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={state.fields["confirm_password"]}
                            onChange={(e) => handleChange("confirm_password",e)}
                            className={state.errors.confirm_password ? "invalid" : ""
                            }
                          />
                        <div className="full-width form-field flex-center">
                          <button
                            type="primary"
                            className="btn"
                            loading={state.isSubmitLoading}
                            onClick={(e) => submit(e, null, false)}
                          >Sign Up
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="d-space full-width">
                      <div className="signup-tab-dots ">
                        <a
                          onClick={tab1}
                          className={`${state.tab1 ? "active" : ""}`}
                        ></a>
                        <a
                          onClick={tab2}
                          className={`${state.tab2 ? "active" : ""}`}
                        ></a>
                      </div>
                      
    <p className="forgot">By clicking sign up, you agree to our
      Terms of service and <Link to="/privacy-policy"> Privacy policy </Link></p>

    <p>Already have an Account?
      <button onClick={redirectToSignin} style={{background: "transparent"}}>
      <Link to="#">Sign In</Link>
      </button>
      </p>
      
                    </div>
                  </FormMain>
              
            
            </Modal>
    </>
  )
}

export default Signup
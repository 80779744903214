import { Checkbox, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import { apiConst } from '../../../constants/api'
import Api from '../../../utility/apiHandler'
import { responseHelper } from '../../../utility/response'
import { openNotificationWithIcon } from '../../openNotification/openNotification'
import { submit } from '../../shared/Facebook/facebookLogin'
import {  FormMain } from '../authentication-style'
import { useDispatch } from 'react-redux'
import { defaultValidators } from '../authHelper'
import { LoginSocialFacebook } from "reactjs-social-login"
import { FacebookLoginButton } from "react-social-login-buttons"

const api = new Api()
const FacebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID

const Login = ({isModalVisible, handleModal, handleSignupModal}) => {
    const [state, setState] = useState({
        fields: {},
        errors: {
          isBothTrue: null
        },
        isSubmitLoading: false,
        checked: false,
        forgotPwdToggle: false,
        showPassword: false
      })
      const history = useHistory()
      const dispatch = useDispatch()
      const validator = useRef( new SimpleReactValidator({
        validators:defaultValidators
      }))
    const resendVerificationLink = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
          setState({...state, isSubmitLoading: true });
          try {
            let data = {};
            data = {
              email: state.fields.email,
            };
    
            const response = await api.post(apiConst.resentVerificationLink, {
              data,
            });
            const responseData = responseHelper(response, true);
            if (responseData && responseData.success) {
              openNotificationWithIcon(responseData?.type, responseData?.title, responseData?.message);
            } else
              openNotificationWithIcon(responseData.type, responseData.title, responseData.message);
            setState({...state, isSubmitLoading: false });
          } catch (e) {
            openNotificationWithIcon("error", "Error", "Oops Something Went Wrong");
            setState({...state, isSubmitLoading: false });
          }
        } else {
          setState({...state, errors: validator.current.getErrorMessages() });
          validator.current.showMessages();
        }
      };
      const handleForgotPassword = async (e) => {
        e.preventDefault();

    if (validator.current.allValid()) {
      setState({...state, isSubmitLoading: true });
      let data = {
        email: state.fields.email,
      };
      api
        .post(apiConst.forgotPassword, { data })
        .then((response) => {
          const data = responseHelper(response, true);
          console.log(response, "xxx", data);
          if (data && data.success) {
            openNotificationWithIcon(
              data?.type,
              data?.title,
              data?.message
            );
          } else {
            openNotificationWithIcon(data.type, data.type, data.message);
          }
          setState({...state, isSubmitLoading: false });
        })
        .catch((e) => {
          openNotificationWithIcon(
            "error",
            "error",
            "Oops Something Went Wrong"
            );
            setState({...state, isSubmitLoading: false });
          });
        } else {
          console.log("ERROR")
    }
      }
      
      const stateError = (field, value) =>{
        let errors = state.errors
        if(field === 'verify'){
          errors[field] = value
        }else{
          errors[field] = value
        }
        setState({...state, errors})
      }

    const handleChange = (field, e) => {
        let fields = state.fields;
    
        if (e.target.value.trim() == "") {
          fields[field] = "";
        } else {
          fields[field] = e.target.value;
        }
        setState({...state, fields });
      };

    const stateValueHandler = (field, value) => {
    setState({...state, [field]: value });
    };

    const handleFBLogin = (res) => {
      console.log(res);
      submit(null, res, true, api, apiConst, responseHelper, null, null, history, dispatch, setState)
    }

    const redirectToSignUp = () => {
      handleModal(false)
      handleSignupModal(true)
    }
    const handleShowPassword = (e) => {
      e.preventDefault()
      setState({...state, showPassword: !state.showPassword})
    }

    useEffect(() => {
      const isChecked = localStorage.getItem("isChecked")
      if(isChecked === "true"){
        setState({...state, fields: {...state.fields, email: localStorage.getItem("email"), password: localStorage.getItem("password")} , checked: true})
      }
    },[])
  return (
    <>
      <Modal className='poup' centered visible={isModalVisible} footer={null} onCancel={() => handleModal(false)}>
      <img src="../images/logo.png" className="logo1" alt=""/>
        <h4>Log in to your fanbase</h4>
      <FormMain
        onSubmit={(e) =>
          submit(
            e,
            null,
            false,
            api,
            apiConst,
            responseHelper,
            validator,
            state,
            history,
            dispatch,
            stateValueHandler,
            stateError
          )
        }
      className='login_box' >
                    
                     <div className='emails'> <Input
                        type="email"
                        value={state.fields?.email}
                        onChange={(e) => handleChange("email",e)}
                        placeholder="Email ID"
                        pattern="/^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|AERO|ARPA|BIZ|COM|COOP|EDU|GOV|INFO|INT|MIL|MUSEUM|NAME|NET|ORG|PRO|TRAVEL|MOBI|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/"
                        className={state.errors?.email ? "invalid" : ""}
                      />
                      {validator.current.message(
                        "email",
                        state.fields?.email,
                        "required|email"
                      )}
                      </div>
                      <div className='password'>
                      <Input
                        type= {state.showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={state.fields?.password}
                        onChange={(e) => handleChange("password",e)}
                        className={state.errors?.password ? "invalid" : ""}
                      />
                        <button onClick={(e) => handleShowPassword(e)}>
                          {state.showPassword ? 
                          <i class="fa fa-eye"></i> :

                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        }
                        </button>

                      {validator.current.message(
                        "password",
                        state.fields?.password,
                        "required|password"
                      )}
                      {state.errors?.isBothTrue === null ? null : <>
                      {state.errors?.isBothTrue == true ? <span style={{color: 'red', marginLeft: '-18rem'}}>Please verify your email</span> :<> {state.errors?.email && state.errors?.password !== null ? null : <>{state.errors?.isBothTrue === false ? <span className='login-error' >Incorrect Email or password</span>: null}</>}</>}
                    </>}
                    </div>
                    <div className="full-width form-field text-checkbox top-margin">
                      <Checkbox
                        onChange={(e) => {setState({...state, checked: e.target.checked })}}
                        checked={state.checked}
                        style={{color:"whitesmoke"}}
                      >
                        Remember Me
                      </Checkbox>
                    </div>
                      <button
                        type="primary"
                        htmlType="submit"
                        className="btn login"
                        loading={state.isSubmitLoading}
                      >
                        LOG IN
                      </button>

                      <LoginSocialFacebook
                      appId={FacebookAppId}
                      onResolve={(response) => handleFBLogin(response)}
                      onReject={(error) => console.log(error)}
                      scope="public_profile, pages_manage_posts, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_show_list,
                      ads_read, ads_management, pages_manage_ads, publish_video, read_insights">
                        <FacebookLoginButton />
                      </LoginSocialFacebook>
                      
                      
                      <a href="#" className="forgot" onClick={() => {
                         handleModal(false)
                         setState({...state, forgotPwdToggle: true})}}>Forgot password?</a>


                  <p>Don’t have an account? <button onClick={redirectToSignUp} style={{background: "transparent"}}>
                   <Link to="#">
                    Sign up!
                   </Link>
                    </button></p>
                  </FormMain>
      </Modal>
                      <Modal className='poup' centered visible={state.forgotPwdToggle} footer={null} onCancel={() => setState({...state, forgotPwdToggle: false})}>
                      <img src="../images/logo.png" className="logo1" alt=""/>
                        <h4>Forgot Password?</h4>
                        <FormMain className="login_box">

                        <div className='emails'> 
                        <Input
                          type="email"
                          value={state.fields?.email}
                          onChange={(e) => handleChange("email",e)}
                          placeholder="Email ID"
                          pattern="/^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|AERO|ARPA|BIZ|COM|COOP|EDU|GOV|INFO|INT|MIL|MUSEUM|NAME|NET|ORG|PRO|TRAVEL|MOBI|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/"
                          className={state.errors?.email ? "invalid" : ""}
                          />
                          </div>
                          <div style={{display: "flex", width: "100%"}}>
                          <button style={{marginRight: "10px"}}
                        type="primary"
                        htmlType="submit"
                        className="btn"
                        onClick={(e) => { e.preventDefault() 
                          setState({...state, forgotPwdToggle: false})}}
                      >
                        CANCEL
                      </button>
                      <button
                        type="primary"
                        htmlType="submit"
                        className="btn"
                        loading={state.isSubmitLoading}
                        onClick={handleForgotPassword}
                      >
                        SEND
                      </button>
                      </div>
                      </FormMain>
                        <p>Please enter your Email ID and we will send you a link to set new password.</p>
                        </Modal>
    </>
  )
}
export default Login

import React, { Component } from "react";

const imagePath = process.env.REACT_APP_SMARTLINK_IMAGE;

export default class Testimonials extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (


      <div className="testi-thumb">

        <ul className="mobile-slider owl-carousel">

          <li>

            <div className="testi-img">

              <img src="../images/Pork Pie.jpeg" alt=""/>

            </div>

            <div className="testi-text">

              <h4>Mickey Stafford, Pork Pie.</h4>
              <p>We spent so much time and money to record our album but then had no way to promote and get real listeners and fans, thanks to Fanify we’re getting lots of monthly listeners and engagement across social media. - Mickey Stafford, Pork Pie.</p>
              <p>New York, 2022</p>

            </div>


          </li>

          <li>

            <div className="testi-img">

              <img src="../images/Shed.png" alt=""/>

            </div>

            <div className="testi-text">

              <h4>Cillian Donlon</h4>
              <p>Fanify has been an amazing help to show our music to people who really dig it and come back looking for more! - Cillian Donlon, Shed</p>
              <p>2022</p>

            </div>


          </li>


          <li>

            <div className="testi-img">

              <img src="../images/feature1.jpg" alt=""/>

            </div>

            <div className="testi-text">

              <h4>Track your fanbase the smart way</h4>
              {/* <p>I wasted time trying to learn advertising and marketing tools to properly promote my music but got nowhere. Now I’m using Fanify and my fanbase is growing everyday! - Rusty Daniels, singer-songwriter. */}
{/* </p> */}
              {/* <p>2023</p> */}

            </div>


          </li>

          <li>

            <div className="testi-img">

              <img src="../images/feature2.jpg" alt=""/>

            </div>

            <div className="testi-text">

              <h4>World Leading Technology</h4>
              {/* <p>I was struggling trying to get my music out there and felt like I would never have a fanbase. With only a few weeks using Fanify I finally have a real fanbase and it’s growing every day! - Damien Thorn, Counterfeit Club..</p> */}
              {/* <p>2022</p> */}

            </div>


          </li>

          <li>

            <div className="testi-img">

              <img src="../images/DJ OMURCHU.png" alt=""/>

            </div>

            <div className="testi-text">

              <h4>DJ OMURCHU</h4>
              <p>I was only using Fanify for a few days and one of my mix videos went viral, amazing! - DJ OMURCHU
</p>
              <p>2022</p>

            </div>


          </li>

          <li>

            <div className="testi-img">

              <img src="../images/Pete Rust.jpg" alt=""/>

            </div>

            <div className="testi-text">

              <h4>Pete Rust,</h4>
              <p>I tested the new version of our technology by recording a cover of God of Thunder on my phone in our rehearsal room, uploaded to YouTube on a brand new account and promoted it using Fanify for 7 days at $1 per day. I received over 3.5k views, 36 likes, 20 subscribers and 9 comments with a lot of kind words—unbelievable results for a new video of a musician with no organic sharing or promotion. Check it out:  https://www.youtube.com/watch?v=od3ZMlz2Q7o - Pete Rust, CEO of Fanify and solo artist.</p>
              <p>Ireland, 2022</p>

            </div>


          </li>

          <li>

            <div className="testi-img">

              <img src="../images/DJ Rabhadh.png" alt=""/>

            </div>

            <div className="testi-text">

              <h4>DJ Rabhadh</h4>
              <p>I promoted my DJ mixes on the Lockup DJs YouTube channel with Fanify and got amazing results for peanuts. Over 7.2k views at the moment, see for yourself:  https://www.youtube.com/channel/UCIAYZKHRuvJThE4tIoJZPWA - DJ Rabhadh, Head of BusDev at Fanify and DJ.
</p>
              <p>Ireland, 2022</p>

            </div>


          </li>

          <li>

            <div className="testi-img">

              <img src="../images/feature3.jpg" alt=""/>

            </div>

            <div className="testi-text">

              <h4>Free Features- free for life</h4>
              {/* <p>I got really frustrated trying to promote my music by watching digital marketing tutorials, wasting money and getting nowhere. With Fanify I spend $1 a day and I’m getting likes, comments and 100s of views a day.  Declan Quinn, RGD.</p> */}
              {/* <p>New York, 2022</p> */}

            </div>


          </li>

          <li>

            <div className="testi-img">

              <img src="../images/The Urge.jpeg" alt=""/>

            </div>

            <div className="testi-text">

              <h4>The Urge</h4>
              <p>We used Fanify  on our promo video for only a few days and started getting inquiries for bookings. - The Urge, wedding band."</p>
              <p>2022</p>

            </div>


          </li>

        </ul>

      </div>




      );
  }
}  